.headerLogin{
    display: flex;
    padding: .5vw 5vw;
    justify-content: space-between;

    :global {
        .logo{
            svg{
                width: 7vw;
                height: 4.2vw;
            }
        }

        .rightBlock {
            display: flex;
            align-items: center;

            menu {
                display: flex;
                list-style: none;
                margin-right: 5vw;

                li {
                    margin-right: 1.5vw;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                a {
                    color: #000;
                    text-decoration: none;
                    font-size: .9vw;
                    font-weight: 500;
                }
            }
        }

        .navigation{
            display: flex;

            .try-service,
            .account{
                display: flex;
                background: @grey-1;
                padding: 1vw 2vw;
                align-items: center;
                font-weight: 600;
                font-size: .9vw;
                color: #000;
                text-decoration: none;

                &:hover{
                    background: @grey-2;
                }

                label{
                    margin-left: .6vw;
                    cursor: pointer;
                }

                svg{
                    width: 1.1vw;
                    height: 1.1vw;
                }
            }

            .try-service{
                background: @blue-3;
                margin-left: .6vw;
                color: #FFF;
                font-weight: 700;

                &:hover{
                    background: @blue-2;
                }
            }
        }
    }
}
@hack: true; @import "/vercel/path0/src/assets/less/variables.less";