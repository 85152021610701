.ajaxLoader{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255,255,255,.96);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;

    :global{
        .spinner {
            display: flex;
            width: 3vw;
            justify-content: space-between;
        }

        .spinner > div {
            width: 11px;
            height: 11px;
            background-color: @blue-1;
            border-radius: 100%;
            display: inline-block;
            -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        }

        .spinner .bounce1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
        }

        .spinner .bounce2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
        }
    }
}

.ajaxLoaderFixedSpinner{
    :global{
        .spinner {
            position: fixed;
            top: 50%;
        }
    }
}

.ajaxLoaderFixed{
    position: fixed;
}
@hack: true; @import "/vercel/path0/src/assets/less/variables.less";