*{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body{
    font-family: @font-family;
    margin: 0;
}

input:focus,
input,
textarea,
textarea:hover,
a,
a:hover,
&.animate,
&.animate:hover,
.animate,
.animate:hover{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.animate-slow,
.animate-slow:hover{
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
}

input,
select,
textarea{
    font-family: @font-family;
}

.hide{
    display: none;
}

.left{
    float: left;
}

.right{
    float: right;
}

.center{
    margin: 0 auto;
}

.cover-size{
    background-size: cover!important;
}

.rounded{
    border-radius: .3vw;
}

.rounded-mini{
    border-radius: .18vw;
}

.rounded-full{
    border-radius: 10vw;
}

.section{
    padding: 0 5vw;
    display: flex;
}

.shadow{
    box-shadow: 0 0 .5vw rgba(0,0,0,.1);
    border: none;
    background: #FFF;

    &:hover{
        box-shadow: 0 0 .5vw rgba(0,0,0,.2);
    }

    &:focus,
    &:active{
        border: none;
        outline: none;
    }
}

.button{
    display: flex;
    border: none;
    padding: 1vw 1.5vw;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    line-height: 100%;

    &.full-width{
        width: 100%;
    }

    span{
        font-weight: 700;
        font-size: .9vw;
    }

    img,
    svg{
        width: 1.2vw;
        height: 1.2vw;
        margin-right: .5vw;
    }

    &.blue{
        background: @blue-1;
        border: 1px solid @blue-2;
        color: #FFF;

        &:hover{
            background: @blue-2;
        }
    }

    &.dark-grey{
        background: rgba(0,0,0,.1);
        color: #000;

        svg{
            fill: #000;
        }
    }

    &.no-button{
        cursor: default;
    }

    &.blue-hover{
        &:hover{
            background: @blue-2!important;
        }
    }

    &.grey{
        background: @grey-3;
        border: 1px solid @grey-2;

        &:hover{
            background: @grey-4;
            border: 1px solid @grey-5;
        }
    }

    &.green{
        background: @green-1;
    }

    &.yellow{
        background: @yellow-1;

        &:hover{
            background: @yellow-1-hover;
        }
    }

    &.yellow-border{
        background: @yellow-2;
        border: 1px solid @yellow-1;

        &:hover{
            background: @yellow-1;
            border: 1px solid @yellow-1;
        }
    }

    &.red{
        background: @red-1;
        color: #FFF;
        border: 1px solid @red-2;

        &:hover{
            background: @red-2;
        }
    }

    &.blue-dark{
        background: @blue-4;
        color: #FFF;

        &:hover{
            background: @blue-4-hover;
        }
    }

    &.mini{
        padding: 0 1vw;
        height: 2.4vw;

        span{
            font-size: .7vw;
        }

        img,
        svg{
            width: .8vw;
            height: .8vw;
        }
    }

    &.middle{
        padding: 0 1.2vw;
        height: 3vw;

        span{
            font-size: .9vw;
        }
    }
}