.footerLoginPage{
    flex-direction: column;
    display: flex;
    padding: 2vw 5% 0;

    :global {
        .top-block,
        .bottom-block{
            display: flex;

            .left-block{
                width: 20%;
            }

            .right-block{
                width: 80%;
            }

            .logo{
                svg{
                    width: 9vw;
                    height: 5vw;
                }
            }

            .social-networks{
                display: flex;

                a{
                    display: flex;
                    margin-right: .2vw;

                    &:last-child{
                        margin-bottom: 0;
                    }

                    svg{
                        width: 1.4vw;
                        height: 1.4vw;
                    }
                }
            }

            .language,
            .location{
                display: flex;
                cursor: pointer;
                margin-bottom: .6vw;
                align-items: center;

                &:last-child{
                    margin-bottom: 0;
                }

                svg{
                    width: 1vw;
                    height: 1vw;
                }

                span{
                    margin-left: .4vw;
                    font-size: 1vw;
                    font-weight: 600;
                }
            }
        }

        .top-block{
            align-items: center;
        }

        .bottom-block{
            margin-top: 1vw;

            .bottom-menu{
                display: flex;

                ul{
                    margin-right: 1.2vw;
                    list-style: none;
                    padding-left: 0;

                    &:last-child{
                        margin-right: 0;
                    }

                    li{
                        margin-bottom: .3vw;

                        &:last-child{
                            margin-bottom: 0;
                        }

                        a{
                            font-weight: 600;
                            text-decoration: none;
                            color: rgba(0,0,0,.8);
                            font-size: .9vw;

                            &.top-link{
                                font-size: 1.1vw;
                                font-weight: 700;
                            }

                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .headquarters{
                display: flex;
                margin-top: 2vw;

                .headquarter{
                    display: flex;
                    flex-direction: column;
                    margin-right: 2vw;
                    width: 15vw;

                    &:last-child{
                        margin-right: 0;
                    }

                    .title{
                        font-size: 1.2vw;
                        font-weight: 700;
                        display: flex;
                        align-items: center;

                        svg{
                            width: 1.2vw;
                            height: 1.2vw;
                        }

                        span{
                            margin-left: .5vw;
                        }
                    }

                    .address{
                        margin-top: .6vw;
                        font-size: .8vw;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.footerCopyrightLoginPage{
    border-top: 1px solid @grey-1;
    padding: 1.5vw 5vw;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    margin-top: 4vw;
    display: flex;

    :global {
        .copyright{
            font-weight: 700;
            font-size: .9vw;
        }

        .documents{
            a{
                color: rgba(0,0,0,.5);
                text-decoration: none;
                margin-right: 1vw;
                font-size: .8vw;

                &:last-child{
                    margin-right: 0;
                }

                &:hover{
                    text-decoration: underline;
                    color: rgba(0,0,0,1);
                }
            }
        }
    }
}
@hack: true; @import "/vercel/path0/src/assets/less/variables.less";