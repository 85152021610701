.dashboardMainBlock{
    padding: 6vw 2vw 2vw calc(20% + 2vw)!important;
    flex-direction: column;
    display: flex;
}

.dashboardTopBlock{
    margin-bottom: 2vw;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 80%;
    right: 0;
    background: #FFF;
    top: 0;
    padding: .64vw 2vw;
    z-index: 999999;

    :global{
        .search{
            width: 50%;
            position: relative;

            .icon{
                position: absolute;
                top: .7vw;
                left: 1vw;
                z-index: 101;
                width: 1vw;
                height: 1vw;
                opacity: .5;
            }

            input{
                border: none;
                background: @grey-3;
                padding: .6vw 1.5vw .6vw 2.5vw;
                width: 100%;
                font-weight: 600;

                &:focus{
                    outline: none;
                    background: @grey-1;
                }
            }
        }

        .actions{
            display: flex;
            position: relative;
            z-index: 101;

            .action{
                width: 2.5vw;
                height: 2.5vw;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                &:hover{
                    background: @grey-1;
                }

                svg{
                    width: 1.4vw;
                    height: 1.4vw;
                }
            }

            .logout{
                margin-left: 1vw;
            }
        }
    }
}

.dashboardTopBlockNoBackground{
    background: transparent;
}


@hack: true; @import "/vercel/path0/src/assets/less/variables.less";