.sidebar{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    border-right: 1px solid @grey-1;
    padding: 1.5vw;
    width: 20%;
    height: 100vh;
    align-items: flex-start;
    background: #FFF;
    overflow: scroll;
    z-index: 999;

    :global{
        .hello{
            font-size: 1vw;
            font-weight: 500;
            padding: .5vw .7vw;
            display: flex;
            align-items: center;

            .avatar{
                background: @blue-1;
                border-radius: 50%;
                width: 2vw;
                height: 2vw;
                display: flex;
                align-items: center;
                justify-content: center;

                svg{
                    width: 1vw;
                    height: 1vw;
                    fill: #FFF;

                    g,
                    path{
                        fill: #FFF;
                    }
                }
            }

            .text{
                margin-left: .5vw;
            }

            span{
                font-weight: 700;
            }
        }

        .top-actions{
            width: 100%;
            margin-top: 1vw;

            .button{
                height: 2.8vw;

                svg{
                    width: 1vw;
                    height: 1vw;
                }

                span{
                    font-size: .8vw;
                }
            }
        }

        .chooseLocation{
            margin-top: .5vw;
            display: flex;
            cursor: pointer;
            align-items: center;
            position: relative;
            border: 1px solid transparent;

            .ajax-loader{
                position: fixed;
            }

            &:hover{
                border: 1px solid rgba(0,0,0,.1);
            }

            &.inactive{
                cursor: default;

                &:hover{
                    border: 1px solid transparent;
                    background: none;
                    cursor: default;
                }
            }

            .active-location{
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 2.4vw;
                padding: 1vw .7vw;
            }

            .available-locations{
                position: absolute;
                top: 2.4vw;
                background: #FFF;
                width: 100%;
                overflow: hidden;
                z-index: 9999;

                .location{
                    padding: .3vw .7vw;

                    span{
                        font-size: .85vw;
                        font-weight: 600;
                    }

                    &:hover{
                        background: @grey-1;
                    }
                }
            }

            &:hover{
                background: @grey-1;
            }

            svg{
                width: .9vw;
                height: .9vw;

                &.svg-change{
                    width: .7vw;
                    height: .7vw;
                }
            }

            span{
                margin-left: .5vw;
                margin-right: 1vw;
                font-weight: 700;
                font-size: .9vw;
            }
        }

        menu{
            list-style: none;
            padding-left: 0;
            width: 100%;
            margin: 1vw 0 0 0;

            &.sub-menu{
                margin-top: 2vw;
            }

            li{

                &:last-child{
                    margin-bottom: 0;
                }

                a{
                    display: flex;
                    align-items: center;
                    color: #000;
                    text-decoration: none;
                    font-size: .85vw;
                    font-weight: 600;
                    padding: .5vw .7vw;
                    opacity: .6;

                    &:hover{
                        background: @grey-1;
                        opacity: 1;
                    }

                    &.active{
                        opacity: 1;
                        color: @blue-1;
                        background: @grey-1;

                        svg{
                            fill: @blue-1;

                            path,
                            g{
                                fill: @blue-1;
                            }
                        }
                    }

                    span{
                        margin-left: .5vw;
                    }

                    svg{
                        width: 1vw;
                        height: 1vw;
                    }
                }
            }
        }
    }
}
@hack: true; @import "/vercel/path0/src/assets/less/variables.less";